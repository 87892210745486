import { getCloudinaryThemeFileUrl } from '@/integrations/cloudinary/helpers'

export interface TitleDecorationProps {
  className?: string
}

const TitleDecoration = ({ className = '' }: TitleDecorationProps) => {
  return (
    <>
      <div className={`root ${className}`} />
      <style jsx>
        {`
          .root {
            margin-bottom: 0;
            background-image: url(${getCloudinaryThemeFileUrl('images/icons/waves/blue.png')});
            height: 30px;
            background-repeat: no-repeat;
            background-size: contain;
          }
        `}
      </style>
    </>
  )
}

export default TitleDecoration
